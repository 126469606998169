import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { gapi } from 'gapi-script';
import { imgPasswordInVisible, imgPasswordVisible, quizMusic} from "./assets";
import firebase from "firebase";
import { ACTIONS } from "../../../components/src/types";
import StorageProvider from '../../../framework/src/StorageProvider';
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/drive';


export interface ValidResponseType {
  data: object;
}
export interface ErrorPayloadType {
  key: string;
}
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}
export interface StudentAttributes {
  student_name: string;
  student_image: string | null;
  answer: string & {answer:string}[];
  teacher_correct_answer: string;
  current_stage_score?:number;
  answers?:AnswerPlayload[] 
}

export interface MCQQuestion {
  question: string;
  type: string;
  attribute: StudentAttributes[];
  student_count?:number|string;
  students_answered_count?:number|string;
}

export interface QuestionPayload {
  question: string;
}

export interface AnswerPlayload {
  answer: string;
}

export interface StudentAnswer {
  answer: string;
  question: string;
  correct_answer: boolean;
  teacher_correct_answer: string;
  current_stage_score?:number;
}

export interface StudentAttribute {
  student_name: string;
  student_image: string | null;
  answer: StudentAnswer[];
  student_answer: AnswerPlayload[];
  answers?:StudentAnswer[]
}

export interface MainQuestion {
  main_question: string;
  type: string;
  questions: QuestionPayload[];
  attribute: StudentAttribute[];
  student_count?:number|string;
  students_answered_count?:number|string;
}

export interface WordCloudAnswer {
  text: string;
  value: number
}
export interface PollAnswer{
  "options_id": number,
  "option": string,
  "percentage": number
}

interface QuizAnswerResponse {
  student_answer: string & {answer:string}[];
  student_image:string,
  student_name:string,
  teacher_correct_answer:string,
  current_stage_score?: number;
}

interface OpenEndedAnswersQuiz {
  answers: {student_answer:string,teacher_correct_answer:string,current_stage_score?: number}[],
  question: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  role: string|null;
  room: string;
  lessonId:string;
  messages:any[];
  stages:any[];
  currentStage:any;
  showQuestion:boolean;
  stageIndex:number;
  participants:any[];
  lessonName:string;
  className:string;
  lessonActualId:string;
  parentStage:any;
  screen:string
  isDescHide: boolean;
  rightAnswerTick: boolean;
  isOpenResult: boolean;
  isStudentResult: boolean;
  rightKwlTick: boolean;
  isStudentKwlData: boolean;
  studentResultList: MCQQuestion | MainQuestion;
  isMatching: boolean;
  isMatching_question: boolean;
  isOpenName: boolean;
  pollResult:  {
    "options_id": number,
    "option": string,
    "percentage": number
  }[];
  wordsAnswer:{text:string,value:number}[],
  getLiveStreamCanvas:boolean;
  lessonStartTime:string,
  endLessonModal:boolean;
  endLessonSuccess:boolean;
  subStages:any[];
  isQuiz:boolean,
  isSignedIn: boolean;
  saveRepsonses: any;
  checked: boolean;
  showCommentsIcon: boolean;
  isLoading: boolean;
    // Customizable Area End
 

}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveStreamingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetLessonStages:string=""
  leaveLessonApiId:string=""
  unsubscribeFunctions: any[]=[]
  getStudentNameApicallId: string = "";
  fetchWordCloudAnswerApiId:string="";
  fetchPollingQuestionApiId:string="";
  apiGetQuizStages:string="";
  getStudentNameApicallIdForQuiz:string="";
  saveToDrive:string=""
  saveCanvasApiId:string="";
  audio?:HTMLAudioElement=undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.updateHighlightToFirebase = this.updateHighlightToFirebase.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      role:"",
      room:'',
      lessonId:"",
      messages:[],
      stages:[],
      currentStage:null,
      stageIndex:0,
      showQuestion:false,
      participants:[],
      lessonName:"",
      className:"",
      lessonActualId:"",
      parentStage:null,
      screen:"Lesson",
      isDescHide: false,
      rightAnswerTick: false,
      isOpenResult: false,
      isStudentResult: false,
      rightKwlTick: false,
      isStudentKwlData: false,
      studentResultList: {
        question: "",
        type: "",
        attribute: []
      },
      isMatching: false,
      isMatching_question: false,
      isOpenName: false,
      wordsAnswer:[],
      pollResult:[],
      lessonStartTime:"",
      endLessonModal:false,
      endLessonSuccess:false,
      getLiveStreamCanvas:false,
      subStages:[],
      isQuiz:false,
      checked: false,
      isSignedIn: false,
      saveRepsonses: [],
      showCommentsIcon:false,
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let error = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const callId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    this.handleGetStageResponse(callId, response, error)
    if (this.isValidResponse(response)) {
      this.apiSuccessCallBacks(callId, response);
    }
    this.wordCloudResponse(callId, response)
    if(response && !response.error){
      this.quizStudentNameResponse(callId, response)
    }
    else{
      this.handleGeStudentNameResponse()
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (window.localStorage.getItem("authToken") === null) {
      this.props.navigation.navigate("EmailAccountLogin");
    }
    this.setState({
      role:window.localStorage.getItem("role"),
    })
    const id = this.props.navigation.getParam("id") as string
    const isQuiz = id.includes('quiz')
    this.setState({
      lessonId:id,
      room:`LIVE_${id}`,
      isQuiz: isQuiz
    })
    this.initFirebase(id)
    this.setLessonTime()
    this.getLessonStages(id,isQuiz)
    this.loadClientInfo()
  }

  async componentWillUnmount() {
    this.unsubscribeFunctions.forEach(unsubscribe=>unsubscribe())
    if(this.audio)this.audio.pause();
  }
  async awaitStageUpdate() {
    return new Promise<void>((resolveFn) => {
      const intervalChck = setInterval(() => {
        if (this.state.stages.length>0) {
          clearInterval(intervalChck);
          resolveFn();
        }
      }, 10);
    });
  }
  async initFirebase(id:string){
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyADj2MO1ayerqP0BnVuVgdl_cQBhYd2GD4",
        authDomain: "iqlearn-2ada0.firebaseapp.com",
        projectId: "iqlearn-2ada0",
        storageBucket: "iqlearn-2ada0.appspot.com",
        messagingSenderId: "273787809764",
        appId: "1:273787809764:web:48c68a55c65a154d3ee1f1",
        measurementId: "G-F5K4FKLMB5"
      });
    }
    const room=`LIVE_${id}`
    const db = firebase.firestore();
    const unsubscribeMessage = db
      .collection("messages")
      .where("room", "==", room)
      .orderBy("createdAt")
      .onSnapshot((querySnapshot) => {
        const updatedComments = querySnapshot.docs.map((doc) => doc.data());
        const currentMsgs = this.state.messages.length;
        this.setState({messages:updatedComments,showCommentsIcon:currentMsgs<updatedComments.length})
      });
      await this.awaitStageUpdate();
      const unsubcribeCurrentStage = db
      .collection("currentStage")
      .where("room", "==", room)
      .onSnapshot((querySnapshot) => {
        const currentStageData = querySnapshot.docs.map((doc) => doc.data());
        const curStageId = currentStageData[0]?.stage;
        const curStage = this.state.stages.find((x:any)=>x.id==curStageId)
         if(!curStage) return
        const parentStage = this.getParentStage(curStage)
        let subStages=[]
        if(curStage && curStage.id == parentStage.id){
          subStages= this.getSubStages(curStage.attributes.stage_number)
        }
        this.setState({currentStage:curStage,parentStage:parentStage,subStages:subStages}, () => this.getStudentNameCallApi())
      });

      const unsubcribeParticipants = db
      .collection("users")
      .where("room", "==", room)
      .onSnapshot((querySnapshot) => {
        const participants = querySnapshot.docs.map((doc) => doc.data());
        this.setState({participants:participants})
      });


      this.unsubscribeFunctions=[
        unsubscribeMessage,
        unsubcribeCurrentStage,
        unsubcribeParticipants
      ]
  }
  
   getParentStage(curStage?:any){
   
    let parentStage = this.state.parentStage
    if(curStage && curStage?.attributes?.stage_number){
      let stageNum = curStage?.attributes?.stage_number;
      if(/^\d+$/.test(stageNum))
      {
        parentStage = curStage

      }
      else{
        stageNum = stageNum.toString().match(/^\d+/)[0];
        parentStage = this.state.stages.find((curStage:any)=>
        curStage.attributes.stage_number == stageNum
        )
      }
    }
    return parentStage
  }

   getSubStages = (parentString:string) => {
    return this.state.stages.filter((curStage:any)=>
      curStage.attributes.stage_number.toString().match(/^\d+/)[0] == parentString
      && curStage.attributes.stage_number != parentString
    )
  }
  async loadCurrentStage(currentStage: any, room:string) {
    const db = firebase.firestore();
    const doc = {
      stage: currentStage.id,
      lessonName:this.state.lessonName,
      className:this.state.className,
      showQuestion:this.state.showQuestion,
      showResult:this.state.isOpenResult,
      room: room,
      screen:this.state.screen,
      lessonStartTime:this.state.lessonStartTime,
      highlightJson:"",
      highlightJsonDesc:""
    };
    try {
      await this.removeExistingStage();
      await db.collection("currentStage").add(doc);
    } catch (error) {
    }
  }

  async updateCurrentStageShowQuestion(showQuestion: boolean) {
    const db = firebase.firestore();

    try {
      // Query for the document with the specified room
      const querySnapshot = await db
        .collection("currentStage")
        .where("room", "==", this.state.room)
        .get();
      // If there's a document with the specified room, update it
      if (!querySnapshot.empty) {
        querySnapshot.forEach((docRef) => {
          db.collection("currentStage").doc(docRef.id).update({
            showQuestion: showQuestion,
          });
        });
      } else {
        console.log("No room found");
      }
    } catch (err) {}
  }
    
  async updateCurrentStageShowResult(showResult: boolean) {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db
        .collection("currentStage")
        .where("room", "==", this.state.room)
        .get();
      if (!querySnapshot.empty) {
        querySnapshot.forEach((docRef) => {
          db.collection("currentStage").doc(docRef.id).update({
            showResult: showResult,
          });
        });
      }
    } catch (err) {}
  }
  async updateCurrentStageAudio(seconds: number,isPlaying:boolean) {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db
        .collection("currentStage")
        .where("room", "==", this.state.room)
        .get();
      if (!querySnapshot.empty) {
        querySnapshot.forEach((docRef) => {
          db.collection("currentStage").doc(docRef.id).update({
            seconds: seconds,
            isPlaying:isPlaying
          });
        });
      }
    } catch (err) {}
  }  
  async updateHighlightToFirebase(highlightJson: string,isTitle:boolean) {
    const db = firebase.firestore();

    try {
      const querySnapshot = await db
        .collection("currentStage")
        .where("room", "==", this.state.room)
        .get();
      if (!querySnapshot.empty) {
        if(isTitle){
          querySnapshot.forEach((docRef) => {
            db.collection("currentStage").doc(docRef.id).update({
              highlightJson: highlightJson,
            });
          });
        }
        else{
          querySnapshot.forEach((docRef) => {
            db.collection("currentStage").doc(docRef.id).update({
              highlightJsonDesc: highlightJson,
            });
          });
        }        
      }
    } catch (err) {
    }
  }
  getNextStageIndex = () => {
    let nextStageIndex = this.state.stageIndex+1;
    let showQuestion = this.state.showQuestion
    let hasSubStage = this.state.subStages.length>0
    if(!showQuestion && hasSubStage){
      const mainStages = this.state.stages.filter((stage:any)=>/^\d+$/.test(stage.attributes.stage_number))
      const curStageIndex = mainStages.findIndex((curStage:any)=>curStage.id==this.state.currentStage.id)
      if(curStageIndex==mainStages.length-1){
        return this.state.stages.length
      }
      nextStageIndex = this.state.stages.findIndex((curStage:any)=>curStage.id==mainStages[curStageIndex+1].id)
    }
    return nextStageIndex
  }

  getPrevStageIndex = () => {
    let prevStageIndex = this.state.stageIndex-1;
    let stageNum = this.state.stages[prevStageIndex].attributes?.stage_number;
    if(/^\d+$/.test(stageNum) || this.state.showQuestion){
      return prevStageIndex
    }
    else{
      const mainStages = this.state.stages.filter((stage:any)=>/^\d+$/.test(stage.attributes.stage_number))
      const curStageIndex = mainStages.findIndex((curStage:any)=>curStage.attributes.stage_number==stageNum.match(/^\d+/)[0])
      prevStageIndex = this.state.stages.findIndex((curStage:any)=>curStage.id==mainStages[curStageIndex].id)
      return prevStageIndex
    }
  }

  nextStage(){
    const nextIndex = this.getNextStageIndex();
    if (nextIndex-1 === this.state.stages.length - 1) return;
    const currentStage = this.state.stages[nextIndex];
    let showQuestion = this.state.showQuestion
    const stageNum = currentStage?.attributes?.stage_number;
      if(/^\d+$/.test(stageNum))
      {
        showQuestion = false
      }
    this.setState((prevState) => ({
      stageIndex: nextIndex,
      showQuestion: showQuestion,
      isDescHide: false,
      rightAnswerTick: false,
      isStudentResult: false,
      isOpenResult: false,
      rightKwlTick: false,
      pollResult:[],
      wordsAnswer:[],
      isOpenName: false,
      studentResultList: {
        question: "",
        type: "",
        attribute: []
      },
      isMatching: false,
      isMatching_question: false,
      currentStage:null
    }),()=>{
      this.loadCurrentStage(currentStage,this.state.room);
    })};

  prevStage(){
    if (this.state.stageIndex === 0) return;
    const prevIndex = this.getPrevStageIndex()
    const currentStage = this.state.stages[prevIndex]
    let showQuestion = this.state.showQuestion
    const stageNum = currentStage?.attributes?.stage_number;
      if(/^\d+$/.test(stageNum))
      {
        showQuestion = false
      }
    this.setState((prevState) => ({
      stageIndex: prevIndex,
      showQuestion: showQuestion,
      isDescHide: false,
      rightAnswerTick: false,
      isOpenResult: false,
      isStudentResult: false,
      rightKwlTick: false,      
      isMatching: false,
      isMatching_question: false,
      pollResult:[],
      wordsAnswer:[],
      isOpenName: false,
      studentResultList: {
        question: "",
        type: "",
        attribute: []
      },
      currentStage:null
    }),()=>{
      this.loadCurrentStage(currentStage,this.state.room);
    })};
    

  async removeExistingStage () {
    const db = firebase.firestore();
    try {
      const querySnapshot = await db.collection("currentStage").where("room","==",this.state.room).get();
      const batch = db.batch();
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
    } catch (error) {
    }
    try {
      await db.collection("canvas").doc(this.state.room).update({
        "canvas": ""
      })
    } catch (error) {
          }
    localStorage.removeItem("liveAnnotation")
    localStorage.removeItem("liveAnnotationDesc")
  }

  setShowQuestion(){
    this.setState({showQuestion:!this.state.showQuestion})
    this.updateCurrentStageShowQuestion(!this.state.showQuestion)
  }
  getLessonStages(id:string|number,isQuiz:boolean=false){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(isQuiz){
      this.apiGetQuizStages = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getQuizStagesApi}?quiz_code=${id}`
      );
    }
    else{
      this.apiGetLessonStages = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getLessonStagesApi}?lesson_code=${id}`
      );
    }    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setCanvasToStorage = (responseJson:{meta:{whiteboard_data:object|string}}) => {
    if(responseJson.meta.whiteboard_data && Object.entries(responseJson.meta.whiteboard_data).length>0){
      StorageProvider.set("whiteBoardCanvas", responseJson.meta.whiteboard_data)
    }
    else{
      StorageProvider.remove("whiteBoardCanvas")
    }
  }
  handleGetStageResponse( apiRequestCallId: string,
    responseJson: any,
    errorReponse: string){
      if(apiRequestCallId != null && apiRequestCallId === this.apiGetLessonStages){
                if(responseJson.data.length===0){
          return
        }
        if (responseJson.data) {
          this.setState({
            lessonName:responseJson.meta.lesson_name,
            className:responseJson.meta.class_name,
            lessonActualId:responseJson.data[0].attributes.lessons_id
          })
         
          this.setCanvasToStorage(responseJson)
          this.loadLessonToFirestore(responseJson.data)
        } 
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if(apiRequestCallId != null && apiRequestCallId === this.apiGetQuizStages){
        if(responseJson.data.length===0){
          return
        }
        if (responseJson.data) {
          this.setState({
            lessonName:responseJson.meta.quiz_name,
            className:responseJson.meta.class_name ?? "Test",
            lessonActualId:responseJson.data[0].attributes.quiz_id
          })
          this.setMusic(responseJson.meta.is_music)
          this.loadLessonToFirestore(responseJson.data,responseJson.meta.is_music)
        } 
        else {
          this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
  }
  setMusic = (isMusic?:boolean) => {
    if(isMusic){
      this.audio = new Audio(quizMusic)
      this.audio.loop = true;
      this.audio.play()
    }
  }
  
  setLessonTime = () => {
    const currentTime = new Date();
    const year = currentTime.getUTCFullYear();
    const month = String(currentTime.getUTCMonth() + 1).padStart(2, '0'); 
    const day = String(currentTime.getUTCDate()).padStart(2, '0');
    const hours = String(currentTime.getUTCHours()).padStart(2, '0');
    const minutes = String(currentTime.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentTime.getUTCSeconds()).padStart(2, '0');
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    this.setState({
        lessonStartTime: formattedTime
      }
    )
  }
  async loadLessonToFirestore(data:any,isMusic?:boolean){
    const thisRoomId = `LIVE_${this.state.lessonId}`
    const sortedLessonStages = data.sort(
      (a: any, b: any) =>
        a.attributes.stage_number - b.attributes.stage_number
    );
    this.loadCurrentStage(sortedLessonStages[0], thisRoomId);
    this.setState({stages:sortedLessonStages}) 
  }

  leaveLesson = () => {
    this.setState({endLessonModal:true,endLessonSuccess:false})
  }

  setScreen=(screenName:string)=>{
    this.setState({screen:screenName},()=>{
      this.loadCurrentStage(this.state.stages[this.state.stageIndex],this.state.room);
    })
  }
  
  clearDataOnEndLesson=()=>{
    //remove db all comments
    const db = firebase.firestore();
    const id = this.props.navigation.getParam("id")
    const roomId=`LIVE_${id}`
    
    db
    .collection('messages')
    .where("room", "==", roomId)
    .get().then(querySnapshot => {
      querySnapshot.docs.forEach(snapshot => {
          snapshot.ref.delete();
      })
    });
    //remove db all participants
    db
    .collection('users')
    .where("room", "==", roomId)
    .get().then(querySnapshot => {
      querySnapshot.docs.forEach(snapshot => {
          snapshot.ref.delete();
      })
    })
    db
    .collection('lesson')
    .where("room", "==", roomId)
    .get().then(querySnapshot => {
      querySnapshot.docs.forEach(snapshot => {
          snapshot.ref.delete();
      })
    });
    db
    .collection('currentStage')
    .where("room", "==", roomId)
    .get().then(querySnapshot => {
      querySnapshot.docs.forEach(snapshot => {
          snapshot.ref.delete();
      })
    });
  }

  kwlResponseData = (kwlType: string) => {
    if (kwlType === "kwl_chart" || kwlType === "t_chart" || kwlType === "venn_diagram" || kwlType === "seed_discussion" || kwlType === "wh_chart") {
      this.setState({
        isStudentKwlData: !this.state.isStudentKwlData,
      });
    }
  };

  handleGeStudentNameResponse = () => {
    if (this.state?.studentResultList?.type === "matching_question") {
      this.setState({
        isMatching_question: !this.state.isMatching_question,
      });
    }
    this.state.isOpenName && this.kwlResponseData(this.state.studentResultList.type);
  };

  handleShowResult = () => {
    const showResult = !this.state.isOpenResult
    this.updateCurrentStageShowResult(showResult)
    let stageName = this.state.isQuiz?this.state.currentStage.attributes.quiz_stage_name:this.state.currentStage.attributes.lesson_stage_name;
    this.setState({
      isOpenResult: showResult
    });
    switch(stageName){
      case ACTIONS.MCQ:
      case ACTIONS.ADD_TEXT:
        this.setState({
          isDescHide: !this.state.isDescHide,
          rightAnswerTick: false
        });
        break;
      case ACTIONS.MULTIMEDIA:
        this.setState({
          rightAnswerTick: !this.state.rightAnswerTick,
          isDescHide: false
        });
        break;
      case ACTIONS.KWL_CHART:
      case ACTIONS.SEED_DISCUSSION:
      case ACTIONS.T_CHART:
      case ACTIONS.VENN_DIAGRAM:
      case ACTIONS.WH_CHART:
        this.setState({
          rightKwlTick: !this.state.rightKwlTick,
          isDescHide: false,
          rightAnswerTick: false
        });
        this.handleStudentResult(false)
        break;
      case ACTIONS.ADD_MATCHING_Q:
        this.setState({
          isMatching: !this.state.isMatching,
          isDescHide: false
        });
        break;
      case ACTIONS.ADD_WORD_CLOUD:
        this.fetchWordCloudAnswer()
        break;
      case ACTIONS.ADD_POLL:
      case ACTIONS.POLLING_Q:
        this.fetchPollingQuestionResult()
        break;
      case ACTIONS.OPEN_ENDED_QUESTION:
        this.handleStudentResult(false)
        break;
    }
  };
  handleMedia = (e:React.SyntheticEvent<HTMLAudioElement,Event>,seconds:number|undefined) => {
    this.updateCurrentStageAudio(seconds ?? 0,e.type=="play")
  };

  apiCall = async (responseBody: APIPayloadType) => {
    let { contentType, method, endPoint } = responseBody;
    const header = {
      "Content-Type": contentType,
      token: await window.localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: MainQuestion & MCQQuestion & any[]) => {
    if (apiRequestCallID === this.getStudentNameApicallId) {
      const studResultList = Array.isArray(responseJson)?responseJson[0]:responseJson;
      if(studResultList &&  studResultList.attribute ){
        const sortedStudentResult = studResultList.attribute.sort((a:any, b:any) => {
          if (a.student_name < b.student_name) return -1;
          if (a.student_name > b.student_name) return 1;
          return 0;
        });
        studResultList.attribute = sortedStudentResult
      }
      this.setState({
        studentResultList: studResultList
      }, () => {
        this.handleGeStudentNameResponse();
      })
    }
   
  };

  getResultData = (stageType:string,responseJson:any[],responseObject:any) => {
    return {
      question:this.state.currentStage.attributes.questions[0].question.question,
      type:stageType,
      attribute:responseJson?responseJson.map((resp:QuizAnswerResponse)=>{
        const stageScore = resp.current_stage_score??0
        const studentAttribute:StudentAttributes={
          answer:resp.student_answer,
          student_image:resp.student_image,
          student_name:resp.student_name,
          teacher_correct_answer:resp.teacher_correct_answer,
          current_stage_score: stageScore
        }
        return studentAttribute
      }):[],      
      students_answered_count:responseObject.students_answered_count,
      student_count:responseObject.student_count
    }
  }
  quizStudentNameResponse=(apiRequestCallID: string, responseObject: any)=>{
    if(apiRequestCallID == this.getStudentNameApicallIdForQuiz){
      let responseJson = responseObject.answers as any[];
      const stageType = this.state.currentStage.attributes.quiz_stage_name as ACTIONS
      let resultObject:MCQQuestion | MainQuestion;
      if(stageType == ACTIONS.MCQ || stageType == ACTIONS.POLLING_Q){
        resultObject = this.getResultData(stageType,responseJson,responseObject)
      }
      else if (stageType == ACTIONS.OPEN_ENDED_QUESTION){
        const studAttr:StudentAttribute[] = responseJson.map((answer:QuizAnswerResponse,index:number)=>{
          return {
            student_name: answer.student_name,
            student_image: answer.student_image,
            answer:[{answer:answer.student_answer,question:"",correct_answer:false,teacher_correct_answer:""}],
            teacher_correct_answer:"",
            student_answer:[{answer:answer.student_answer}]
          }
        });
        resultObject = {
          main_question: this.state.currentStage.attributes.title,
          type: stageType,
          questions:[],
          attribute:studAttr,
          students_answered_count:responseObject.students_answered_count,
          student_count:responseObject.student_count
        }
      }
      else {
        resultObject = {
          main_question: this.state.currentStage.attributes.title,
          type: stageType,
          students_answered_count:responseObject.students_answered_count,
          student_count:responseObject.student_count,
          questions: responseJson.map((resp:{question:string})=>{
            return {
              question: resp.question
            }
          }),
          attribute:responseJson.length>0?responseJson[0].answers.map((answer:QuizAnswerResponse,index:number)=>{
            return {
              student_name: answer.student_name,
              student_image: answer.student_image,
              answer: responseJson.map((resp:OpenEndedAnswersQuiz)=>{
                const stageScore = resp.answers[index].current_stage_score??0
                return {
                  answer: resp.answers[index].student_answer,
                  question: resp.question,
                  correct_answer: resp.answers[index].teacher_correct_answer==resp.answers[index].student_answer,
                  teacher_correct_answer: resp.answers[index].teacher_correct_answer,
                  current_stage_score: stageScore
                }
              }),
            }
          }):[]
        }
      }
      
      this.setState({
        studentResultList: resultObject
      }, () => {
        this.handleGeStudentNameResponse();
      })
    }
  }
  wordCloudResponse = (apiRequestCallID: string, responseJson: WordCloudAnswer[] & PollAnswer[]) => {
    if (apiRequestCallID === this.fetchWordCloudAnswerApiId) {
      this.setState({wordsAnswer:responseJson ?? []})

    }
    else   if (
      apiRequestCallID != null &&
      (apiRequestCallID === this.fetchPollingQuestionApiId)
    ) {

      if (responseJson) {
        this.setState({pollResult:responseJson ?? []})
      } 
    }
    else if(
      apiRequestCallID != null &&
      (apiRequestCallID === this.saveCanvasApiId)
    ){           

      if(this.state.checked){            
        this.jsonToDrive(responseJson)
      } 
      this.setState({endLessonSuccess:true,endLessonModal:true})

    } else if (apiRequestCallID != null && (apiRequestCallID === this.saveToDrive)) {
      this.handleSaveToDrive(responseJson);
    }
  };

  handleSaveToDrive = (responseJson: WordCloudAnswer[] & PollAnswer[]) => {
    if (this.state.checked && this.state.isQuiz) {
      this.jsonToDrive(responseJson);
    }
    this.navigateAfterSave();
  };
  
  navigateAfterSave = () => {
    const target = this.state.endLessonSuccess ? "TeacherLibrary" : "Dashboard";
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), target);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleStudentResult = async (isOpenName:boolean = true) => {
    const stateIsOpenName = this.state.isOpenName;
    if(isOpenName){
      this.setState({
        isOpenName: !stateIsOpenName,
        isStudentResult: !stateIsOpenName,
        isStudentKwlData: stateIsOpenName ? false : this.state.isStudentKwlData
      });
    }

    if(!stateIsOpenName || !isOpenName){
      this.getStudentNameCallApi()
    }
    
  };
  getStudentNameCallApi = async () => {
    const nameApiId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.GET,
      endPoint: this.state.isQuiz?`${configJSON.getQuizStudentNamesApi}?quiz_id=${this.state.lessonActualId}&quiz_markup_tool_id=${this.state.currentStage?.id}`:`${configJSON.getStudentNameEndPoint}?lesson_id=${this.state.currentStage?.attributes?.lessons_id}&lesson_markup_tools_id=${this.state.currentStage?.id}`
    });
    if(this.state.isQuiz){
      this.getStudentNameApicallIdForQuiz = nameApiId
    }
    else{
      this.getStudentNameApicallId = nameApiId
    }
  }

  fetchWordCloudAnswer=()=>{
    const header = {
      token: window.localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchWordCloudAnswerApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getWordclouAnswersApiEndPoint}?lesson_markup_tools_id=${this.state.currentStage?.id}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchPollingQuestionResult=()=>{
    const header = {
      token: window.localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchPollingQuestionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isQuiz?`${configJSON.fetchQuizPollingAnswerApiEndPoint}?quiz_markup_tools_id=${this.state.currentStage?.id}`:`${configJSON.fetchPollingAnswerApiEndPoint}?lesson_markup_tools_id=${this.state.currentStage?.id}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  modalClose=()=>
  {
    if(this.state.endLessonSuccess){
      this.endLesson()
    }
    else{
      this.setState({endLessonModal:false})
    }
  }

  jsonToDrive = async (
    jsonResponse: any
  ) => {    
    
    const randomPrefix = Math.floor(Math.random() * 900) + 100;
  
    let fileName: string;   
    let type = jsonResponse.data.type
    if(type == 'lesson'){
      fileName = `${randomPrefix}_Live_${jsonResponse.data.attributes.lesson_name}`;
    } else {
      fileName = `${randomPrefix}_Live_${jsonResponse.data.attributes.quiz_name}`;
    }
  
    const responseString = JSON.stringify(jsonResponse, null, 2); 
    const blob = new Blob([responseString], { type: "application/json" });
  
    const fileMetadata = {
      name: `${fileName}.json`,
      mimeType: "application/json",
    };
  
    const formData = new FormData();
    formData.append(
      "metadata",
      new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
    );
    formData.append("file", blob);
  
    const accessToken = gapi.auth.getToken().access_token;
  
    await fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
      {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
        body: formData,
      }
    );

  };

  createTacherLibrary=(checked:boolean)=>{        
    if(checked){   
      this.setState({checked}) 
    }   
    this.endLesson(!this.state.isQuiz)
    !this.state.isQuiz && this.setState({getLiveStreamCanvas:true})
  }

  endLesson = async (whiteBoard:boolean=false) => {
    this.setState({isLoading:true,endLessonModal:false}) 
    let whiteBoardCanvas = await StorageProvider.get("whiteBoardCanvas");
    !whiteBoard && this.clearDataOnEndLesson()
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
    const header = {
      "Content-Type":configJSON.validationApiContentType,
      token: window.localStorage.getItem("authToken"),
    };
    if(this.state.isQuiz){

      let body:{quiz_id:string,live_quiz:boolean}={      
        "quiz_id":  this.state.lessonActualId,
        "live_quiz": false
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endLiveQuiz}`
      ); 
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PATCH
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    else 
    {  
      let body:{id:string,live_lesson:boolean,whiteboard_data?:string}={      
        "id": this.state.lessonActualId,
        "live_lesson": false,
      }
      if(whiteBoard){
         whiteBoardCanvas = await StorageProvider.get("whiteBoardCanvas");
        body={
          ...body,
          "whiteboard_data": whiteBoardCanvas
        }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endLiveLession}`
      );   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PUT
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)

    );
   if(whiteBoard){
    this.saveCanvasApiId = requestMessage.messageId; 
   }

   else{
    this.leaveLessonApiId = requestMessage.messageId; 
   }
   
   
   if(!whiteBoard){
     this.saveToDrive = requestMessage.messageId  
   }
   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
    
  }

  onChangeSwitch = () => {
    if(!this.state.isSignedIn){
      gapi.auth2.getAuthInstance().signIn();
    }
  };

  clientIntialization = () => {
    gapi.client.init({
       apiKey: configJSON.WebAPIKey,
      clientId: configJSON.WebClienctID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    }).then(() => {
      gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
      this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  };

  loadClientInfo = () => {
    gapi.load('client:auth2', this.clientIntialization);
  };

 
  updateSigninStatus = (isSignedIn: boolean) => {    
    if (isSignedIn) {
      this.setState({isSignedIn})
    }
  };

  readComments = () => {
    this.setState({showCommentsIcon:false})
  }
  // Customizable Area End
}
