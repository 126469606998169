import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import JoditEditor from "jodit-react";
const useStyles = makeStyles(() => ({
  editArea: {
    height: "740px !important",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginBottom: "50px",
    padding: "24px 12px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
}));
const AddText = (props: any) => {
  const titleRef = React.useRef<any>(null);
  const { setAddTextState, addTextState } = props;
  const classes = useStyles();
  const handleInput = (event: any) => {
    setAddTextState(event);
  };
  return (
    <div style={{position:"relative",marginTop:"41px"}}>
        <JoditEditor
          className={classes.editArea}
          ref={titleRef}
          value={addTextState}
          config={{
            readonly: false,
            className: classes.editArea,
            statusbar: false,
            removeButtons: [
              "table",
              "fullsize",
              "line",
              "symbol",
              "preview",
              "|",
              "video",
              "quote",
              "print",
              "symbol",
              "selectall",
              "about",
              "superscript"
            ]
          }}
          tabIndex={1}
          onBlur={handleInput}
          data-testId="stageDescriptionInput"
        />
    </div>
  );
};

export default AddText;
