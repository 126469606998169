import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Alert, Linking } from "react-native";
import DocumentPicker, { DocumentPickerResponse } from 'react-native-document-picker';
import { gapi } from 'gapi-script';
import React, { CSSProperties } from "react";
const GoogleAuth = require("@react-native-google-signin/google-signin");



export enum  uploadType{
  driveUpload,
  googleDocsUpload,
  googleSlidesUpload,
}

interface DriveFile {
  id: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
}

interface DriveListResponse {
  files: DriveFile[];
  nextPageToken?: string;
}
interface FileMetadata{
  
  type: null;
    name: string;
    mimeType: string;
    modifiedTime: string;
}
interface GapiClient {
  drive: DriveApi;
}

interface DriveApi {
  files: DriveFilesApi;
}

interface DriveFilesApi {
  list(params: DriveFilesListParams): Promise<DriveFilesListResponse>;
  get(params: DriveFilesGetParams): Promise<DriveFileGetResponse>;
  delete(params: DriveFilesDeleteParams): Promise<void>;
}

interface DriveFilesDeleteParams {
  fileId: string; 
}

interface DriveFilesListParams {
  pageSize?: number;
  fields: string;
  q?: string;
}

interface DriveFilesGetParams {
  fileId: string;
  alt: string;
}

interface DriveFileGetResponse {
  body: Blob;
  headers: {
    'Content-Type': string;
    'Content-Disposition': string;
  };
}

interface DriveFilesListResponse {
  result: {
      files: DriveFile[];
      nextPageToken?: string;
  };
}

interface DriveFile {
  id: string;
  name: string;
  mimeType: string;
  modifiedTime: string;
}

interface Gapi {
  load(module: string, callback: () => void): void;
  client: GapiClient;
  
}
declare global {
  interface Window {
      gapi: Gapi;
  }
}
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/drive';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onButtonClick?: any;
  importPressed? : boolean;
  setImportPressed?:  (value: React.SetStateAction<boolean>) => void;
  getFilesGdrive?: any
  isCheckboxNeeded?: boolean
  revertColumns?: boolean
  headerLabel?: string
  googleBtnStyle?: CSSProperties
  advanceOptions?: boolean
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userIsSignedIn: boolean;
  googleDocsData : {
    id:string,
    name:string,
    webViewLink:string
  }[];
  uploading:boolean,
  authToken: string,
  isSignedIn: boolean,
  files: { id: string;
    name: string;
    mimeType: string;
    modifiedTime: string;}[],
  filter: string,
  deleting:boolean,
  successMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GsuiteintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  // Customizable Area Start
  uplaodFileCallId:string = "";
  generalFileInputRef:React.RefObject<HTMLInputElement>;
  documentFileInputRef: React.RefObject<HTMLInputElement>;
  slideFileInputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

   
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userIsSignedIn:false,
      googleDocsData:[],
      uploading:false,
      authToken: "",
      isSignedIn: false,
      files: [],
      filter: 'all',
      deleting:false,
      successMessage: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.generalFileInputRef = React.createRef();
    this.documentFileInputRef = React.createRef();
    this.slideFileInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if(this.uplaodFileCallId === apiRequestCallId){
      this.listFiles(this.state.filter);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
  
    const GoogleSignin = this.isPlatformWeb() ? GoogleAuth.GoogleLogin : GoogleAuth.GoogleSignin;
    try {
     let signedIn =  await GoogleSignin.isSignedIn()
     this.setState({userIsSignedIn:signedIn})
    } catch (error) {
      Alert.alert(configJSON.signInErrorMessage,"")
    }
    this.handleClientLoad();
    
  }

   signIn = async ()=> {
    const GoogleSignin = this.isPlatformWeb() ? GoogleAuth.GoogleLogin : GoogleAuth.GoogleSignin;
    try {
      
      GoogleSignin.configure({
        webClientId: configJSON.webClientId, 
        scopes: [configJSON.driveScope],
        
      })
     
      await GoogleSignin.hasPlayServices();

      let userData =  await GoogleSignin.signIn();


      if(userData.idToken !==""){
        this.setState({userIsSignedIn:true})
      }else{
        throw configJSON.loginError
      }
      

    } catch (error) {
      Alert.alert(configJSON.loginError, JSON.stringify(error))
      }
    }

  

    loadInBrowser = async(fileUrl:string) => {
      try {
        await Linking.openURL(fileUrl) 
      } catch (error) {
        Alert.alert(configJSON.docsLinkErrorMessage,JSON.stringify(error))
      }
     
    };



  pickerTypeAsPerUploadType = (receivedUploadType:uploadType)=>{
    switch (receivedUploadType) {
      case uploadType.googleDocsUpload:
        return [DocumentPicker.types.docx,DocumentPicker.types.doc,DocumentPicker.types.pdf]

      case uploadType.googleSlidesUpload:
        return [DocumentPicker.types.ppt,DocumentPicker.types.pptx]
        default:
        return DocumentPicker.types.allFiles
      
    }
  }
  
    pickFile = async (uploadType:uploadType) => {
      try {
        const response = await DocumentPicker.pick({
          type: this.pickerTypeAsPerUploadType(uploadType),
        });
        return response;
      } catch (error) {
        if (DocumentPicker.isCancel(error)) {
          Alert.alert(configJSON.documentPickerErrorMessage, "")
        } else {
          throw error;
        }
      }
    };

    mimeTypeAsPerUploadType = (receivedUploadType:uploadType,filetype:string| null) =>{
      switch (receivedUploadType) {
        case uploadType.googleDocsUpload:
          return configJSON.mimeTypeGoogleDocs

        case uploadType.googleSlidesUpload:
          return configJSON.mimeTypeGoogleSlides

          default:
          return filetype
      
      }
    }


    btnSignInProps = {
      onPress:()=>this.signIn()
    }

    handleClientLoad = () => {
      gapi.load('client:auth2', this.initClient);
    };

    initClient = () => {
      gapi.client.init({
        apiKey: configJSON.WebAPIKey,
        clientId: configJSON.WebClienctID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
        this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      });
    };
    updateSigninStatus = (isSignedIn: boolean) => {
      this.setState({ isSignedIn },()=>{
        if (isSignedIn) {
          this.listFiles();
        }
      });
     
    };

    
  listFiles = async(filter = this.state.filter) => {
    let query = "'root' in parents";


   (gapi as unknown as  Gapi).client.drive.files.list({
      fields: 'nextPageToken, files(id, name, mimeType, modifiedTime,webContentLink,size)',
      q: query,
    }).then((response) => {      
      this.setState({ files: response.result.files, filter });
    })
  };

  deleteFile = async (file:{id:string}) => {    
    this.setState({deleting:true})
      await (gapi as unknown as Gapi).client.drive.files.delete({ fileId: file.id }).then(() => {
        this.listFiles(); 
      }).then(()=> {
        this.setState({ deleting: false, successMessage: 'File deleted successfully.' });
        setTimeout(() => this.setState({ successMessage: '' }), 2000); 
    
      });
   
  };

  uploadFile = (file: Blob, metadata: FileMetadata, mimeTypeOverride: string | null = null) => {
    const formData = new FormData();

    formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    formData.append('file', file);

  const header = {
    Authorization: 'Bearer ' + gapi.client.getToken().access_token,
  };

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.uplaodFileCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.upploadFileGoogleApi
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.googlePostAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

};

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;

    let mimeTypeOverride = null;
    switch (fileType) {
      case 'document':
        mimeTypeOverride = 'application/vnd.google-apps.document';
        break;
      case 'slide':
        mimeTypeOverride = 'application/vnd.google-apps.presentation';
        break;
      default:
        mimeTypeOverride = file.type;
    }

    const fileMetadata: FileMetadata = {
      name: file.name,
      mimeType: mimeTypeOverride,
      modifiedTime: new Date().toISOString(),
      type: null
    };
    
  this.uploadFile(file, fileMetadata,mimeTypeOverride);
    event.target.value = ''; // Reset the input after upload
  };
    handleAuthClick = () => {      
      if(gapi.auth2.getAuthInstance().isSignedIn.get()) {
        window.location.reload()
      }
      else gapi.auth2.getAuthInstance().signIn();
    };
  
    handleSignoutClick = () => {
      gapi.auth2.getAuthInstance().signOut();
    };

    handleFileUploadClick = () => {
      if (this.generalFileInputRef.current) {
        this.generalFileInputRef.current.click();
      }
    };
    handleDocumentFileUploadClick = () => {
      if (this.documentFileInputRef.current) {
        this.documentFileInputRef.current.click();
      }
    };
  
    handleSlideFileUploadClick = () => {
      if (this.slideFileInputRef.current) {
        this.slideFileInputRef.current.click();
      }
    };

    handleView = (file:{id:string})=>{
      const link = `http://drive.google.com/file/d/${file.id}/view`;
      window.open(link);
    }

    openGdrive = ()=>{
      const link = `https://drive.google.com/drive/my-drive`;
      window.open(link);
    }

    getFilesData = async (fileId: string, fileName:string) => {
        const response = await (gapi as unknown as Gapi).client.drive.files.get({
          fileId: fileId,
          alt: 'media'
        });
        const blob = new Blob([response.body], { type: response.headers['Content-Type'] });
        const file = new File([blob], fileName, { type: blob.type });        
        this.props.getFilesGdrive(file); 
    };

  // Customizable Area End
}
