import React, { useEffect,useState } from "react";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { greenTick } from "../../assets";
import { redCross } from "../../../../analytics/src/assets";
import StaticCanvas from "../../../../../components/src/StaticCanvas.web";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./react-beautiful-dnd.css" 

const useStyles = makeStyles(() => ({
  question: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "20px",
  },
  instruction: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
    marginBottom: "15px",
  },
  matchingBoxA: {
    padding: "16px 12px",
    width: "100%",
    border: "1px solid rgba(43, 61, 125, 0.24)",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
    marginBottom: "10px",
    boxSizing:"border-box",
    "&.question-layout":{
      width:"100%",
    }
  },
  matchingBoxB: {
    padding: "16px 12px",
    width: "100%",
    border: "1px solid rgba(43, 61, 125, 0.24)",
    borderRadius: "10px",
    backgroundColor: "rgba(43, 61, 125, 0.08)",
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
    marginBottom: "10px",
    cursor: "move",
    boxSizing:"border-box",
    "&.question-layout":{
      width:"100%",
    }
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "21px",
    color: "#282829",
    fontWeight: 700,
    width: "170px",
  },
  headingLayout: {
    display: "flex",
    marginBottom: "15px",
    justifyContent: "space-between",
    textAlign: "center",
  },

  mainLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    padding: "30px 60px",
    "& .result-grid-main":{
      "& .result-grid-item":{
        padding:"0 8px",
        '@media (orientation: landscape) and (min-width: 700px) and (max-height: 600px)': {
          width:"50%"
        },
      }
    }
  },
  contentBox: {
    maxWidth: "419px",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "30px",
    position:"relative"
  },
  submitBtn: {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
  },
  disabledBtn: {
    width: "107px",
    height: "44px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: "#2B3D7D",
    borderRadius: "50px",
    opacity: "0.5",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#2B3D7D",
    },
  },
  submitBtnLayout: {
    cursor: "pointer",
    marginTop: "25px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#3F526D",

    "& img":{
      width:"20px",
      padding:"0 5px",
      borderRadius:"50%",
    },

    "& .correct":{
      color: "#34C759",
      fontWeight: 500,
      display: "inline-block",
      marginRight: "6px"
    },

    "& .incorrect":{
      color: "#FD5F5A",
      fontWeight: 500,
      display: "inline-block",
    },
  },
  inCorrectOption: {
    border:"1px solid #FD5F5A",
    cursor:"none",
    backgroundColor:"rgba(253, 95, 90, 0.08)"
  },
  correctOption: {
    border:"2px solid #34C759",
    cursor:"none"
  },
  mainLayoutResult: {
    display: "flex",
  height: "100%",
  padding: "80px 15px 0",
  "& .result-grid-main": {
    "& .result-grid-row": {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "8px"
    },
    "& .result-grid-item": {
      padding: "0 8px",
      flex: "1",
    }
  }
  },
  contentBoxResult: {
    width:"100%",
    backgroundColor: "#FFF",
    display: "flex",
    boxShadow: "0px 0px 10px 0px #F1F5F9",
    borderRadius: "10px",
    flexDirection: "column",
    padding: "0",
    margin:"0 auto",
    "& *":{
      boxSizing: "border-box",
    },
    position:'relative'
  },
  correctAnswer: {
    backgroundColor: "#34C759",
    color: "#FFF",
    border:"0",
    padding: "16px 12px",
    width: "100%",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "10px",
    height:"56px",
  }
}));

const getCanvas = (stage:any,setHeight:React.Dispatch<React.SetStateAction<number>>) => {
  const hasCanvasData = stage?.attributes?.canvas_data;
  let jsonCanvas: object | undefined;
  let canvasSize: string | undefined;
  if (hasCanvasData) {

      const parsedData = JSON.parse(hasCanvasData);
      canvasSize = parsedData.canvasSize;
      jsonCanvas = parsedData.canvas;
  }
  return  (jsonCanvas &&
    <Box style={{position:"absolute",width:"90%",height:"max-content",}}>
      <StaticCanvas canvasData={jsonCanvas} id="canvasId" canvasSize={canvasSize?JSON.parse(canvasSize):undefined}  callbackCanvas={setHeight}/>
      </Box>)
}

const StudentMatchingLive = (props: any) => {
  const [height,setHeight] = useState(0);
  const classes = useStyles();
  const { stage, showQuestion, showResult, showAnswers, stageAnswers, isQuizStudent, isQuizTimedOut, isGuest } = props;
  const pairs = stage.attributes.pairs ?? stage.attributes.matching_pairs;
  const canAnswer = (stageAnswers ?? []).length <= 0 && !isQuizTimedOut && !isGuest;
  const leftArray = pairs.map((pair: any) => pair.pair_question);
  const [rightArray, setRightArray] = useState<string[]>(pairs.map((pair: any) => pair.pair_answer));
  const backgroundImage = stage.attributes.background_image?.url
  useEffect(()=>{
    setRightArray(pairs.map((pair: any) => pair.pair_answer))
  },[pairs])

  useEffect(()=>{
    if((stageAnswers ?? []).length == 0 ) return
    if(isQuizStudent){
      setRightArray(stageAnswers.map((answer:{student_answer:string})=>answer.student_answer))
    }
    else{      
      const currentStageAnswer = (stageAnswers ?? []).length>0?stageAnswers[0]:null;
      if(!currentStageAnswer || !currentStageAnswer.answers) return
      let newRightArray = [];
      for (let index = 0; index < stage?.attributes.pairs.length; index++) {
        newRightArray.push(index<currentStageAnswer.answers.length?currentStageAnswer.answers[index].answer:"")
      }
      setRightArray(newRightArray)
    }
  },[stageAnswers])

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
  
    setRightArray((prev) => {
      const items = [...prev];
      [items[result.source.index], items[result.destination.index]] = 
        [items[result.destination.index], items[result.source.index]];
      return items;
    });
  };

  const handleSubmit = () => {
    const submissionData = leftArray.map((question: string, index: number) => ({
      question: question,
      answer: rightArray[index]
    }));
    props.addChartSubmitData(submissionData); 
  };

  const getResultClass = (pairIndex:number,answer:string) => {
    if(showResult && (stageAnswers ?? []).length>0){
      return pairs[pairIndex].pair_correct_answer == answer ? classes.correctOption : classes.inCorrectOption
    }
    return ""
  }

  const getCorrectCount = () => {
    return pairs.filter((pair:any,index:number)=>rightArray[index]==pair.pair_correct_answer).length;
  }
  const getIncorrectCount = () => {
    return pairs.filter((pair:any,index:number)=>rightArray[index]!=pair.pair_correct_answer).length;
  }

  const getAnswerText = () => {
    return (
      (stageAnswers ?? []).length>0?
    <>
      Your answer is <img src={greenTick} /> <span className="correct">{getCorrectCount()} Correct</span> and <img src={redCross} /> <span className="incorrect">{getIncorrectCount()} Incorrect</span>
    </>:
    <>
      <img src={redCross} /> Not answered
    </>  
    )
  }
  const getAnswers = () => {
    return pairs.map(
      (pair: any) => 
      <Box
        className={classes.correctAnswer}
      >
        {pair.pair_correct_answer}
      </Box>
    );
  }
  return (
    <Box className={showAnswers?classes.mainLayoutResult:classes.mainLayout} style={backgroundImage && { 
      backgroundImage: `url("${backgroundImage}")`,
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
     }}>
      <Box className={showAnswers?classes.contentBoxResult:classes.contentBox}>
        <Typography className={classes.instruction}>
          {showAnswers?'Matching Question Results' :`You can drag the options in column 'B' with the correct matching.
          Match column ‘A’ with its corrected pairs of column ‘B’`}
        </Typography>
        <Box>
          <Typography className={classes.question}  dangerouslySetInnerHTML={{
            __html: stage?.attributes.title
            }}
            />
          {getCanvas(stage,setHeight)}
        </Box>
        <Box style={{marginTop:`${height}px`}}   className="question-layout-main" >
          <Box className={classes.headingLayout}>
            <Box className={classes.heading}>A</Box>
            <Box className={classes.heading}>B</Box>
          </Box>
          <DragDropContext data-test-id="dragDropContext" onDragEnd={handleDragEnd}>
          <Droppable data-test-id="droppable" droppableId="droppable">
          {(provided) => (
          <Grid container className="result-grid-main" {...provided.droppableProps} ref={provided.innerRef}>
            {leftArray.map((question: string, index: number) => (
              <Grid container key={index} className="result-grid-row">
                <Grid item md={6} className="result-grid-item">
                  <Box className={`${classes.matchingBoxA} question-layout`}>{question}</Box>
                </Grid>
               <Grid item md={6} className="result-grid-item">
            <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={showResult || !canAnswer}>
              {(provided, snapshot) => (
                <div
                  data-test-id={`draggable-${index}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={`${classes.matchingBoxB} ${getResultClass(index, rightArray[index])}`}
                  style={{
                    ...provided.draggableProps.style,
                    opacity: snapshot.isDragging ? 0.5 : 1,
                    transform: snapshot.isDragging ? provided.draggableProps.style?.transform : "none",
                    transition: snapshot.isDragging ? "transform 0.2s ease" : "none",
                  }}
                 >
                  {rightArray[index]}
                </div>
              )}
            </Draggable>
          </Grid>
        </Grid>
      ))}
      {provided.placeholder}
    </Grid>
  )}
</Droppable>

          </DragDropContext>
          {!showAnswers && (
            <Box className={classes.submitBtnLayout}>
              {showResult ? getAnswerText() : canAnswer && (
                <Button
                  className={!showQuestion ? classes.disabledBtn : classes.submitBtn}
                  onClick={showQuestion && handleSubmit}
                  data-testID='submit'
                >
                  Submit
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StudentMatchingLive;
