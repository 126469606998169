import React, { useEffect, useState } from "react";
import { styled, Modal, Box, Button, FormControl, RadioGroup, FormControlLabel, Radio, Popover, IconButton , Grid, Divider, Switch,Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AddCircleIcon, settingBtn, whiteSetTimer, timer, points, addBackground, whiteAddBackground, blueTimer, whitePoints, whiteDoublePoint, whiteMusic, blueDoublePoint, blueMusicBtn, Cross } from "../../assets";
import AddBackgroundImageModal from "../../../../CFWordCloud/src/Teacher/components/AddBackgroundImageModal.web";
import GenericButton from "../../../../../components/src/GenericButton.web";
import StorageProvider from "../../../../../framework/src/StorageProvider";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from "@material-ui/icons/Close";
interface Option {
  id: number;
  text: string;
  isCorrect: boolean;
}
interface IProp{
  getOptions:(data:any)=>void;
  mcqState: any;
  mcqSettingState: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean,
  }
  handleMcqClick:(options: {
    checkedA: boolean,
    checkedB: boolean,
    checkedC: boolean
  }) => void;
  preview: string | null; 
  setPreview: (url: string | null) => void;
  updateTime: (minutes:string, seconds:string) => void;
  handleSwitchChange: (value: boolean, name: string) => void;
  openEndedState:{
    checkedA: false,
    checkedB: false,
    checkedC: false
  }
  handleOpenEndedChange: (value: boolean, name: string) => void;
  setBackgroundImage?:(image:any)=>void
}

interface TimeSet {
  minutes: string;
  seconds: string;
}


const handleTimeScroll = (
  event: React.WheelEvent<HTMLInputElement>, 
  type: string, 
  minutes: number, 
  seconds: number, 
  setMinutes: Function, 
  setSeconds: Function
) => {
  const delta = getDelta(event);
  clearPreviousTimeout();
  
    handleScroll(type, delta, minutes, seconds, setMinutes, setSeconds);
  
};

const getDelta = (event: React.WheelEvent<HTMLInputElement>): number => {
  return event.deltaY > 0 ? 1 : -1;
};

const clearPreviousTimeout = () => {
  let timehandleout = null;
  if (timehandleout !== null) {
    clearTimeout(timehandleout);
  }
};

const handleScroll = (
  type: string, 
  delta: number, 
  minutes: number, 
  seconds: number, 
  setMinutes: Function, 
  setSeconds: Function
) => {
  if (shouldResetMinutes(delta, type, minutes)) {
    resetMinutes(setMinutes, delta);
  } else if (shouldResetSeconds(delta, type, seconds)) {
    resetSeconds(setMinutes, setSeconds, delta);
  } else {
    updateTime(type, delta, setMinutes, setSeconds);
  }
};

export const resetMinutes = (setMinutes: Function, delta: number) => {
  setMinutes(delta === 1 ? 0 : 59);
};

const shouldResetMinutes = (delta: number, type: string, minutes: number): boolean => {
  return (delta === 1 && type === "minutes" && minutes === 59) ||
         (delta === -1 && type === "minutes" && minutes === 0);
};

const shouldResetSeconds = (delta: number, type: string, seconds: number): boolean => {
  return (delta === 1 && type === "seconds" && seconds === 59) ||
         (delta === -1 && type === "seconds" && seconds === 0);
};

const updateTime = (
  type: string, 
  delta: number, 
  setMinutes: Function, 
  setSeconds: Function
) => {
  if (type === "minutes") {
    setMinutes((prevMinutes: number) => Math.min(Math.max(prevMinutes + delta, 0), 59));
  } else {
    setSeconds((prevSeconds: number) => Math.min(Math.max(prevSeconds + delta, 0), 59));
  }
};

export const resetSeconds = (
  setMinutes: Function, 
  setSeconds: Function, 
  delta: number
) => {
  setMinutes((prevMinutes: number) => (prevMinutes + delta + 60) % 60);
  setSeconds((prevSeconds: number) => (delta === 1 ? 0 : 59));
};

const ToggleSwitch = withStyles((theme) => ({
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor:"white"
  },
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    "& .MuiSwitch-track":{
      boxSizing: "border-box"
    }
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "grey",
  },
  checked: {},
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        boxSizing: "border-box"
      },
    },
  },
}))(Switch);

const useStyles = makeStyles(() => ({
  editArea: {
    height: "740px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    marginBottom: "50px",
    padding: '20px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    position:"relative",
  },
  optionWrapper: {
    border: "1px dashed rgba(149, 157, 165)",
    borderRadius: "10px",
    marginBottom: "20px",
    marginTop: "60px",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "start",
  },
  optionbody: {
    display: "flex",
    gap: "10px",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 30px)"
  },
  optioninputCorrect: {
    flex:"2 0 0",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "58px",
    fontSize: "16px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 800,
    paddingLeft:"15px"
  },
  optioninputIncorrect: {
    flex:"2 0 0",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "58px",
    fontSize: "16px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 400,
    paddingLeft:"15px"
  },
  normalBtn: {
    borderRadius: "10px",
    padding: "10px",
    textTransform: "none",
    border: "1px solid #3F526D",
    color: "#3F526D",
    width: "123px",
    fontFamily: "Poppins",
    fontSize: "12px",
    margin: "0 auto",
    marginBottom: "15px",
    fontWeight: 500,
    justifyContent: "start",
    whiteSpace: "nowrap",
  },
  optionMark: {
    borderRadius: "3px",
    padding: "0px 10px",
    textTransform: "none",
    border: "1px solid #ccc",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    height: "56px",
    display: "flex",
    flex: "1 0 0",
    alignItems: "center",
    justifyContent: "center",
  },
  correctOption: {
    backgroundColor: "green",
    color: "white",
  },
  mainWrapper: {
    width: "100%"
  },
  formWrapper: {
    display: "block"
  },
  optionHead: {
    fontFamily:"Poppins",
    padding: "15px"
  },
  itemsPops: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "15px 0"
  },
  popupHeading: {
    fontSize: "12px",
    
    fontWeight: 500,
    "&:hover": {
      color: "white"
    }
  },
  settingBtn : {
    position :"absolute",
    right :"10px",
    top :"10px",
    zIndex:200,
    display:"flex"
  },
  firstTimer :{
    width: "40px",
    height: "40px",  
    marginTop: "20px",
    borderRadius:"20px 0px 0px 20px",
    justifyContent:"center",
    display:"flex",
    backgroundColor: "#2B3D7D", 
    alignItems:"center",
    alignContent:"center"
  },
  AddOptionBtn:{
    width:"20px",
    height:"20px",
    marginRight:"8px"
  },
  secondTimer :{
    display:"flex",
    width: "90px", 
    height: "40px", 
    marginTop: "20px",
    borderRadius:"0px 20px 20px 0px",
    alignItems:"center",
    alignContent:"center",
   justifyContent:"center",
   backgroundColor: "white",

 
  },
  timerMainBox :{
    display:"flex",
    borderRadius:"40px"
  },
  icon : {
    "&:hover" : {
      color :"white"
    }
  },
  elementPopup: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    cursor: "pointer",
    color: "#324381",
    "&:hover": {
      background: "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color :"white",
    }, 
  },
}));



const handleTimerEnter = (setIsTimerHovered: (value: boolean) => void) => {
  setIsTimerHovered(true);
};

const handleDoublePointEnter = (setIsDoubleHovered: (value: boolean) => void) => {
   setIsDoubleHovered(true)
}

const handleMusicEnter = (setIsMusicHovered: (value: boolean) => void) => {
  setIsMusicHovered(true)
}
const handleMouseEnter = (setIsHovered: (value: boolean) => void) => {
  setIsHovered(true);
};

const handlePointsLeave = (setIsPointsHovered: (value: boolean) => void) => {
  setIsPointsHovered(false);
}
const handleClose = (setAnchorEl: (value: null) => void) => {
  setAnchorEl(null);
};
const handleModalOpen = (setModalOpen: (value: boolean) => void,setAnchorEl: (value: null) => void) => {
  setModalOpen(true)
  setAnchorEl(null)
}
const handleCloseModal = (setModalOpen: (value: boolean) => void) => {
  setModalOpen(false);
}

const handleModalTimerOpen = (setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; },setAnchorEl: { (value: React.SetStateAction<HTMLButtonElement | null>): void; (value: null): void; }) => {
  setIsModalOpen(true)
  setAnchorEl(null)
}
const handleModalTimewrClose = (setIsModalOpen: (isOpen: boolean) => void): void => {
  setIsModalOpen(false)
}
const handleDoublePointLeave = (setIsDoubleHovered: (value: boolean) => void) => {
  setIsDoubleHovered(false)
}

const handleMusicLeave = (setIsMusicHovered: (value: boolean) => void) => {
  setIsMusicHovered(false)
}




const handleMouseLeave = (setIsHovered: (value: boolean) => void) => {
  setIsHovered(false);
};

const handleTimerLeave = (setIsTimerHovered: (value: boolean) => void) => {
  setIsTimerHovered(false);
};

const handlePointsHovered = (setIsPointsHovered: { (value: React.SetStateAction<boolean>): void; }) => {
  setIsPointsHovered(true);
}

export const handleAddOption = (options: Option[],setOptions: { (value: React.SetStateAction<Option[]>): void; (arg0: any[]): void; }) => {
  const newOption: Option = { id: options.length ? options.slice(-1)[0].id + 1 : 1, text: '', isCorrect: false };
  setOptions([...options, newOption]);
};

export const handleCancelOption = (options: Option[],setOptions: { (value: React.SetStateAction<Option[]>): void; (arg0: any[]): void; }, id: number) => {
  const updatedOption = options.filter(option => option.id !== id).map((option, indx) => ({...option,id: indx+1})); 
  setOptions([...updatedOption]);
};

const handleSetTimer = (minutes: number,seconds: number,props: IProp,setTimeset: { (value: React.SetStateAction<TimeSet>): void; (arg0: { minutes: string; seconds: string; }): void; },setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; },setIsTimerSet: { (value: React.SetStateAction<boolean>): void; (arg0: boolean): void; }) => {    
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  props.updateTime(formattedMinutes, formattedSeconds)
  setTimeset({
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  });
  setIsModalOpen(false)
  setIsTimerSet(true)
}

const pageType = async (setpageTypeData:(data: string) => void) => {
  let quizName = await StorageProvider.get("pageTypeData")
  setpageTypeData(quizName)
}


const SettingpopOver = (pageTypeData:string | undefined,isTimerSet:boolean,timeSet:{minutes:string,seconds:string},handleClick:React.MouseEventHandler<HTMLButtonElement> ) => {
  const classes = useStyles();
  return (
    <>
      {pageTypeData == "quizName" && <Box className={classes.settingBtn} >
        <Box style={{ display: "flex" }}>
          {isTimerSet &&
            <Box className={classes.timerMainBox}>
              <Box className={classes.firstTimer}>
                <img style={{ height: "20px", width: "18px" }} src={whiteSetTimer} />
              </Box>
              <Box className={classes.secondTimer}>
                <Typography style={{ color: "black" }}>{timeSet.minutes}</Typography>
                <Typography style={{ color: "black" }}>{`:${timeSet.seconds}`}</Typography>
              </Box>
            </Box>
          }
        </Box>
        {inconSetting(handleClick)}
      </Box>}
    </>
  )
}

const inconSetting =(handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined) =>{
  return(
  <IconButton onClick={handleClick} data-test-id="popOverTestId">
    <img src={settingBtn} />
  </IconButton>
  )
}

const Mcq = (props:IProp) => {
  const classes = useStyles();

  const [options, setOptions] = useState<Option[]>([]);
  const mcqOptions= props?.mcqState?.questions?.[0]?.options;
  const [isHovered, setIsHovered] = useState(false);
  const [isTimerHovered, setIsTimerHovered] = useState(false);
  const [isPointsHovered, setIsPointsHovered] = useState(false);
  const [isDoubleHovered, setIsDoubleHovered] = useState(false);
  const [isMusicHovered, setIsMusicHovered] = useState(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>();
  const [backgroundImage, setBackgroundImage] = React.useState<null>(null);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let timeString = props?.mcqState?.countdown_timer ? props?.mcqState.countdown_timer : "00:00"
  let splitTime = timeString?.split(":");
  const [timeSet, setTimeset] = useState<TimeSet>({ minutes: splitTime[0] ? splitTime[0] : "00", seconds: splitTime[1] ?splitTime[1] : "00" });
  const [isTimerSet, setIsTimerSet] = useState<boolean>(props?.mcqState?.countdown_timer);
  const [pageTypeData, setpageTypeData] = React.useState<string>();


  useEffect(()=>{
    pageType(setpageTypeData)
  },[])

  useEffect(()=>{
    props.getOptions(options)
  },[options])

  useEffect(()=>{
    const transformedOptions: Option[] = mcqOptions?.map((apiOption: any, index: any) => ({
      id: index + 1,
      text: apiOption.answer,
      isCorrect: apiOption.marked_as_right_answer
    }));
    
    setOptions(transformedOptions);
  },[mcqOptions])

  useEffect(() => {
    props.setBackgroundImage && props.setBackgroundImage(backgroundImage);
  }, [backgroundImage])
  
  const handleChangeOptionText = (id: number, text: string) => {
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, text } : option
    );
    setOptions(updatedOptions);
  };

  const handleMarkAsCorrect = (id: number) => {
    const updatedOptions = options.map((option) => ({
      ...option,
      isCorrect: option.id === id,
    }));
    setOptions(updatedOptions);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

 

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  };



const adjustedMinutes = minutes - 1 > -1 ? (minutes - 1) : 59;
const formattedMinutes = adjustedMinutes < 10 ? "0" + adjustedMinutes : adjustedMinutes;
const adjustedSecondMinutes = minutes + 1 < 60 ? (minutes + 1) : 0;
const formattedSecondMinutes = adjustedSecondMinutes < 10 ? "0" + adjustedSecondMinutes : adjustedSecondMinutes;
const adjustedSeconds = seconds - 1 > -1 ? (seconds - 1) : 59;
const formattedSeconds = adjustedSeconds < 10 ? "0" + adjustedSeconds : adjustedSeconds;
const adjustedTimes = seconds + 1 < 60 ? (seconds + 1) : 0;
const formattedTimes = adjustedTimes < 10 ? "0" + adjustedTimes : adjustedTimes;
  const openId = Boolean(anchorEl);
  const settingId = openId ? 'simple-popover' : undefined; 
  const backgroundStyle = {
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center', 
    backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url('${props.preview}')`,
  };
  return (
    <Box className={classes.editArea} style={props.preview ? backgroundStyle : undefined}>
      {SettingpopOver(pageTypeData,isTimerSet,timeSet,handleClick)}
      <Popover
      data-test-id="handleCloseBtn"
        id={settingId}
        open={openId}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginLeft: "60px",
          },
        }}
        onClose={()=>handleClose(setAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {FirstDivider(setModalOpen,setAnchorEl,setIsHovered,isHovered)}
        <Divider />
        {SecondDivision(setIsModalOpen,setAnchorEl,setIsTimerHovered,isTimerHovered)}
        <Divider />
        {ThirdDivision(setIsPointsHovered,isPointsHovered,props)}
        <Divider />
        {FourthDivider(setIsDoubleHovered,isDoubleHovered,props)}
      </Popover>
      <Box className={classes.mainWrapper}>
        <FormControl className={classes.formWrapper} component="fieldset">
          <RadioGroup>
            {options?.map((option) => (
              <Box className={classes.optionWrapper} style={{position: "relative"}}>
                <span className={classes.optionHead}>{`Option ${option.id}`}</span>
                <div className={classes.optionbody} key={option.id}>
                  <FormControlLabel
                    data-test-id="radio-btn"
                    value={option.text}
                    control={<Radio style={{ color: "#2B3D7D" }}/> }
                    label=""
                    checked={option.isCorrect}
                    onChange={() => handleMarkAsCorrect(option.id)}
                  />
                    <input
                      className={(option.isCorrect)?classes.optioninputCorrect:classes.optioninputIncorrect}
                      type="text"
                      placeholder={`Answer ${option.id} here`}
                      value={option.text}
                      onChange={(e) => handleChangeOptionText(option.id, e.target.value)}
                      data-testId={`answerInput-${option.id}`}
                    />
                  <Box className={`${classes.optionMark} ${option.isCorrect ? classes.correctOption : ''}`}
                  >
                    Mark as right answer
                  </Box>
                </div>
                <CloseIcon style={{cursor: "pointer", position: "absolute", top: '-15', right: '-15', color: "red", fontSize: "2rem"}} onClick={() => handleCancelOption(options, setOptions, option.id)} />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      {options?.length < 6 && < Button
        data-test-id="add-btn"
        variant="outlined"
        className={classes.normalBtn}
        fullWidth
        onClick={()=>handleAddOption(options,setOptions)}><img src={AddCircleIcon} className={classes.AddOptionBtn} alt="Image" />Add option</Button>}
        <AddBackgroundImageModal
        data-test-id="closeImageModal"
        open={modalOpen}
        onClose={()=>handleCloseModal(setModalOpen)}
        backgroundImage={backgroundImage}
        setBackgroundImage={setBackgroundImage}
        setPreview={props.setPreview}
      />
      <Modal open={isModalOpen} onClose={()=>handleModalTimerOpen(setIsModalOpen,setAnchorEl)}>
        <MainDivMCQ>
          <img className="closIcon" data-test-id="modalTimeTestId"  src={Cross} onClick={()=>handleModalTimewrClose(setIsModalOpen)} />
          <Typography className="pickerModalTitle">Add countdown timer</Typography>
          <Box className="timeSectionWrapper">
              {InPutName(seconds,minutes,setMinutes,setSeconds,formattedMinutes,formattedSecondMinutes)}
              {SecondOptionsMCQ(formattedSeconds,seconds,formattedTimes,minutes,setMinutes,setSeconds)}
          </Box>
          <GenericButton data-test-id="setTimerTestId" handleClick={()=>handleSetTimer(minutes,seconds,props,setTimeset,setIsModalOpen,setIsTimerSet)} label="Set Timer" type={"COLORED"} customStyle={{width: "300px" }}/>
        </MainDivMCQ>
      </Modal>
    </Box >
  );
};


const ThirdDivision = (setIsPointsHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; } ,isPointsHovered: boolean,props: IProp) =>{
  const classes = useStyles();
  return(
  <Box className={classes.elementPopup}
    data-test-id="switchpointTestId"
    onMouseEnter={() => handlePointsHovered(setIsPointsHovered)}
    onMouseLeave={() => handlePointsLeave(setIsPointsHovered)}>
    <Box className={classes.itemsPops}>
      <img src={isPointsHovered ? whitePoints : points} />
      <Typography className={classes.popupHeading}>Points</Typography>
    </Box>
    <Grid item>
      <ToggleSwitch data-test-id="pointTestId" checked={props.mcqSettingState.checkedA ? true : false} onChange={(event) => props.handleSwitchChange(event.target.checked, event.target.name)} name="checkedA" />
    </Grid>
  </Box>
  )
}

export const minutesInc = (minutes: number, setMinutes: Function) => {
  if(minutes == 59){
    setMinutes(0)
  }else{  
    setMinutes((prevMinutes: number) => prevMinutes + 1)
  }
}

export const minutesDec = (minutes: number, setMinutes: Function) => {
    if(minutes == 0){
      setMinutes(59);
    }else{
      setMinutes((prevMinutes: number) => prevMinutes - 1)
    }
}

export const secondsInc = (seconds: number, minutes: number, setSeconds: Function, setMinutes: Function) => {
  if(seconds == 59){
    setSeconds(0)
    if(minutes != 59){
      setMinutes((prevMinutes: number) => prevMinutes + 1)
    }else{
      setMinutes(0)
    }
  }else{
    setSeconds((prevSecond: number) => prevSecond + 1)
  }
}

export const secondsDec = (seconds: number, minutes: number, setSeconds: Function, setMinutes: Function) => {
    if(seconds == 0){
      setSeconds(59)
      if(minutes == 0){
        setMinutes(59)
      }else{
        setMinutes((prevMinutes: number) => prevMinutes - 1)
      }
    } else{
      setSeconds((prevSecond: number) => prevSecond - 1)
    }
}

const InPutName = (seconds:  number  ,minutes: number,setMinutes: Function,setSeconds: Function,formattedMinutes: {},formattedSecondMinutes: {}) =>{
  return(
    <div className="timeSectionMCQ">
      <ArrowDropUpIcon 
        style={{width:'32px', height: '32px'}}
        data-test-id='btnIncMinutes'
        onClick={()=> minutesInc(minutes, setMinutes)}
      />
      <Typography className="passiveTime">{formattedMinutes}</Typography>
      <input
      style={{cursor:'pointer'}}
        data-test-id="handleScrollTestId"
        className="inpt"
        id="hour"
        min={0}
        max={60}
        value={minutes < 10 ? "0" + minutes : minutes}
        onWheel={(event) => handleTimeScroll(event, "minutes", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{formattedSecondMinutes}</Typography>
      <ArrowDropDownIcon 
        data-test-id='btndecMinutes'
        style={{width:'32px', height: '32px'}}
        onClick={()=> minutesDec(minutes, setMinutes)}
      />
    </div>
  )
}


const SecondOptionsMCQ = (formattedSeconds: {},seconds:  number  ,formattedTimes: {},minutes: number,setMinutes: Function,setSeconds: Function) => {
  return(
    <div className="timeSectionMCQ">
      <ArrowDropUpIcon 
        data-test-id='btnIncSeconds'
        style={{width:'32px', height: '32px'}}
        onClick={()=> secondsInc(seconds, minutes, setSeconds, setMinutes)}
      />
      <Typography className="passiveTime">{formattedSeconds}</Typography>
      <input
        id="minute"
        className="inpt"
        min={0}
        max={59}
        value={seconds < 10 ? "0" + seconds : seconds}
        onWheel={(event) => handleTimeScroll(event, "seconds", minutes, seconds, setMinutes, setSeconds)}
        disabled
      />
      <Typography className="passiveTime">{formattedTimes}</Typography>
      <ArrowDropDownIcon 
        data-test-id='btnDecSeconds'
        style={{width:'32px', height: '32px'}}
        onClick={()=> secondsDec(seconds, minutes, setSeconds, setMinutes)}
      />
    </div>
  )
}

const FourthDivider = (setIsDoubleHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; (value: boolean): void; },isDoubleHovered: boolean,props: IProp ) => {
  const classes = useStyles();
  return(
  <Box className={classes.elementPopup}
    data-test-id="doubleTestId"
    onMouseEnter={() => handleDoublePointEnter(setIsDoubleHovered)}
    onMouseLeave={() => handleDoublePointLeave(setIsDoubleHovered)}>
    <Box className={classes.itemsPops}>
      <img src={isDoubleHovered ? whiteDoublePoint : blueDoublePoint} />
      <Typography className={classes.popupHeading} >Double Points</Typography>
    </Box>
    <ToggleSwitch data-test-id="doublePointChangeTestId" checked={ props.mcqSettingState.checkedB ? true : false} onChange={(event) => props.handleOpenEndedChange(event.target.checked, event.target.name)} name="checkedB" />
  </Box>
  )
}

const FirstDivider = (setModalOpen: { (value: React.SetStateAction<boolean | undefined>): void; (value: boolean): void; },setAnchorEl: { (value: React.SetStateAction<HTMLButtonElement | null>): void; (value: null): void; },setIsHovered: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; (value: boolean): void; },isHovered: boolean) =>{
  const classes = useStyles();
  return(
    <Box className={classes.elementPopup}>
    <Box className={classes.itemsPops}
    data-test-id="isHoverTesxtId"
      onClick={()=>handleModalOpen(setModalOpen,setAnchorEl)}
      onMouseEnter={()=>handleMouseEnter(setIsHovered)}
      onMouseLeave={()=>handleMouseLeave(setIsHovered)}>
      <img src={isHovered ? whiteAddBackground : addBackground}/>
      <Typography className={classes.popupHeading} >Add Background Image</Typography>
    </Box>
  </Box>
  )
}


const SecondDivision = (setIsModalOpen: { (value: React.SetStateAction<boolean>): void; (value: boolean): void; } ,setAnchorEl: { (value: React.SetStateAction<HTMLButtonElement | null>): void; (value: null): void; } ,setIsTimerHovered: { (value: boolean): void; (value: boolean): void; } ,isTimerHovered: boolean) =>{
  const classes = useStyles();
  return(
  <Box className={classes.elementPopup}>
    <Box className={classes.itemsPops}
      data-test-id="handlemodalTestId"
      onClick={() => handleModalTimerOpen(setIsModalOpen, setAnchorEl)}
      onMouseEnter={() => handleTimerEnter(setIsTimerHovered)}
      onMouseLeave={() => handleTimerLeave(setIsTimerHovered)}>
      <img src={isTimerHovered ? timer : blueTimer} />
      <Typography className={classes.popupHeading}>Add Countdown Timer</Typography>
    </Box>
  </Box>
  )
}


const MainDivMCQ = styled("div")({
  backgroundColor: "#fff",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "400px",
  width: "calc(100% - 40px)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
  padding: "40px",
  height:"auto",
  borderRadius: "10px",
  "& .closIcon": {
    cursor: "pointer",
    display: "flex",
    top: "10px",
    right: "10px",
    width: "44px",
    height: "44px",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
  },
  "& .pickerModalTitle": {
    
    textTransform: "capitalize",
    color: "#2B3D7D",
    fontSize: "20px",
  },
  "& .timeSectionWrapper": {
   
    paddingBottom:"40px",
    display: "flex",
    gap: "40px",
    paddingTop:"20px",
  },
  "& .timeSectionMCQ": {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  "& .inpt": {
    width: "40px",
    fontSize: "17px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    textAlign: "center",
    borderTop: "1px solid rgb(45, 53, 92)",
    borderBottom: "1px solid rgb(45, 53, 92)",
    cursor:'pointer',
    "&:disabled": {
      backgroundColor: "white",
      color: "rgb(45, 53, 92)",
    },
  },
  "& .setimerBtn": {
    backgroundColor: "rgb(45, 53, 92)",
    color: "#fff",
    textTransform: "capitalize",
    padding:"10px 0px 10px 0px",
    width: "100%",

  }
});

export default Mcq;
