import React from 'react';
import {
  Box,
  Input,
  InputAdornment,
  IconButton,
  Grid
} from "@material-ui/core";
import EmailAccountLoginController,{ Props } from '../EmailAccountLoginController';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { errorIcon } from '../assets';

const gradientBackground = "linear-gradient(133deg, #2B3D7D 31.28%, #39C6B3 181.36%)";
type TextAlignProperty = 'center'
const webStyle = {
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    'label': {
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  },
  inputlabel: {
    fontFamily: "Poppins",
  },
  customInput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    '.MuiInputBase-input': {
      background: 'red'
    },
  },
  loginbtn: {
    background: gradientBackground,
    border: "none",
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginTop: "15px",
    cursor: "pointer"
  },
  loginLink: {
    color: "#2B3D7D",
    cursor: "pointer"
  },
  custominputDisable:{
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
    background: "#eee"
  },
  error:{
    color: "red",
    fontSize: "12px",
    display: "block",
    fontFamily: "Poppins"
  },
  errormsg:{
    height:"50px",
    borderRadius: "14px",
    fontFamily: "Poppins",
    background: "rgba(254,87,90,0.15)",
    width:"90%",
    color:"#fe575a",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize:"14px",
    fontWeight: 700,
    padding: "10px 15px",
    position: "relative",
  },
  loginLabel:{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.16px",
    textAlign: "center" as TextAlignProperty
  },
}
class Signup extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    this.setState({email:"",password:""})
  }
  render() {
    return (
      <Grid container spacing={3}>
        {this.state.signupError && (
        <Grid item xs={12}>
        <Box sx={webStyle.errormsg}>
            <img src={errorIcon} />
            {this.state.signupError}
        </Box>
        </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={webStyle.inputStyle}>
            <label style={webStyle.inputlabel}>Name*</label>
            <Input
              data-test-id="txtInputName"
              placeholder={"Enter your name"}
              fullWidth={true}
              value={this.state.name}
              disableUnderline={true}
              style={this.state.nameError ? { ...webStyle.customInput, border: "1px solid #fe575a" }
              : webStyle.customInput}
            onChange={(e) => this.setName(e.target.value)}
            />
            {this.state.nameError && <label data-test-id="txtInputNameError" style={webStyle.error}>{this.state.nameError}</label>}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={webStyle.inputStyle}>
            <label style={webStyle.inputlabel}>Email ID*</label>
            <Input
              data-test-id="txtInputEmail"
              placeholder={"Enter your email"}
              fullWidth={true}
              value={this.state.email}
              disableUnderline={true}
              style={this.state.emailError ? { ...webStyle.customInput, border: "1px solid #fe575a" }
              : webStyle.customInput}
             onChange={(e) => this.setEmail(e.target.value)}
            />
            {this.state.emailError && <label data-test-id="txtInputEmailError" style={webStyle.error}>{this.state.emailError}</label>}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={webStyle.inputStyle}>
            <label data-test-id="txtInputRoleLabel" style={webStyle.inputlabel}>Role</label>
            <select
              data-test-id="txtInputRole"
              name={"role"}
              defaultValue={""}
              placeholder={"Select your role"}
              style={webStyle.custominputDisable}
              onChange={(e) => this.setState({selectedRole: e.target.value})}
              value={this.state.selectedRole}
            >
              <option value={"Teacher"}>Teacher</option>
              <option value={"Student"}>Student</option>
            </select>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={webStyle.inputStyle}>
            <label style={webStyle.inputlabel}>Password*</label>
            <Input
              data-test-id="txtInputPassword"
              type={this.state.enablePasswordField ? "password" : "text"}
              placeholder={"Enter your password"}
              fullWidth={true}
              disableUnderline={true}
              style={this.state.passwordError ? { ...webStyle.customInput, border: "1px solid #fe575a" }
              : webStyle.customInput}
            value={this.state.password}
            onChange={(e) => this.setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  data-test-id="passwordIcon"
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  edge="end"
                >
                  {!this.state.enablePasswordField ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            />
          {this.state.passwordError && <label data-test-id="passwordError" style={webStyle.error} dangerouslySetInnerHTML={{ __html: this.state.passwordError }}></label>}  
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={webStyle.inputStyle}>
            <label style={webStyle.inputlabel}>Confirm Password*</label>
            <Input
              data-test-id="txtInputConfirmPassword"
              type={this.state.enableConfirmPasswordField ? "password" : "text"}
              placeholder={"Confirm password"}
              fullWidth={true}
              disableUnderline={true}
               style={this.state.confirmPasswordError ? { ...webStyle.customInput, border: "1px solid #fe575a" }
              : webStyle.customInput}
            value={this.state.confirmPassword}
            onChange={(e) => this.setConfirmPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowConfirmPassword}
                  edge="end"
                >
                  {!this.state.enableConfirmPasswordField ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            />
          {this.state.confirmPasswordError && <label data-test-id="passwordError" style={webStyle.error}>{this.state.confirmPasswordError}</label>}  
          </Box>
        </Grid>
        <Grid item xs={12}>
          <button
            data-test-id={"btnEmailLogIn"}
            style={webStyle.loginbtn}
          onClick={() => this.handleFormSubmit()}
          >
            {this.state.loading ? <CircularProgress style={{ color: 'white'}}/> : 'Sign Up'}
          </button>
        </Grid>
        <Grid item xs={12}>
          <p style={webStyle.loginLabel}>Already have an account? <span style={webStyle.loginLink} onClick={this.goToLogin}>Log in</span></p>
        </Grid>
      </Grid>
    )
  }
}

export default Signup;

